import React from 'react'
import LandingPageAdvertorial from '../../../templates/landing-page-advertorial'
import LandingPage from '../../../templates/landing-page'

import content from '../../../data/pages/lp/article/meet-le/content.json'

const AdvertorialMeetLELP = () => (
  <LandingPage classNames='p-landing-page--meet-le' content={content} type='advertorial' />
)

export default AdvertorialMeetLELP
